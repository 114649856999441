/**
 * Provide application wide tag vars here
 */
@import "_variables.module.scss";
@import "layout.scss";

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


/***************************
 * Headers & Paragraphs    *
 ***************************/

h1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 64px;
  color: $secondary;
}

h2 {
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: $secondary-bright;
  font-size: 48px;
  font-weight: bold;
}

h3 {
  color: $secondary;
  font-weight: bold;
  font-size: 24px;
}

p {
  margin-top: 0.75rem !important;
  color: $secondary-bright;
  font-size: 19px;
  white-space: pre-wrap;
}


/***************************
 * Separators              *
 ***************************/

hr {
  width: 100px;
  border: 2px solid $secondary-bright;

  &.large {
    width: 400px;
  }

  &.full {
    width: 100%;
  }

  &.accent {
    border-width: 1px;
    border-color: $accent !important;
  }
}

/***************************
 * Links                   *
 ***************************/



a {
  text-decoration: none;

  &:link {
    color: $white;
    background-color: transparent;
  }

  &:hover {
    color: $secondary-light;
    background-color: transparent;
  }

  &:visited {
    background-color: transparent;
  }

  &:active {
    color: $white;
    background-color: transparent;
  }
}

/***************************
 * Links                   *
 ***************************/

.white {
  color: $white;
}

.primary {
  color: $accent;
}