
@keyframes MoveCubeUpAndDown {
  0% {
    rotate: -360deg;
    top: -.5% !important;
  }
  100% {
    opacity: 1;
    rotate: 0deg;
    top: .5% !important;
  }
}

@keyframes MoveRosaCube {
  0% {
    opacity: 0;
    top: -50%;
  }
  100% {
    opacity: 1;
    top: 21%;
  }
}

@keyframes MoveYellowCube {
  0% {
    opacity: 0;
    top: -50%;
  }
  100% {
    opacity: 1;
    top: 29%;
  }
}

@keyframes MoveBlueCube {
  0% {
    opacity: 0;
    top: -50%;
  }
  100% {
    opacity: 1;
    top: 37%;
  }
}

@keyframes MoveUpDownMobile {
  0% {
    top: -1000px;
  }
  50% {
    top: 140px;
  }
  100% {
    top: 50px !important;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes arrowMovingUpDown {
  0%, 100% {
    top: 10px;
  }
  50% {
    top: 25px;
  }
}

@-webkit-keyframes glide {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-110px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
