@import "_variables.module.scss";

/** INPUT */

.MuiOutlinedInput-root {
  margin-right: $offset-1 !important;
  margin-left: $offset-1 !important;
  border-color: $secondary !important;
  color: $white !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: white !important;
}

.MuiCheckbox-root {
  color: $secondary-bright !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 2px solid $secondary !important;
}

.MuiOutlinedInput-root.Mui-focused {
  border-color: $secondary !important;
}

.MuiFormLabel-root.Mui-focused {
  padding-left: 0.25rem;
  color: $white !important;
}

.MuiFormLabel-root {
  color: $white !important;
}


/**
 * SLIDER
 */
.swiper {
  width: 1000px;
  height: 300px;
  border-radius: $borderRadius;
  overflow: hidden; /* Hide scrollbars */
}

