@import "_variables.module.scss";
@import "layout.scss";

.w-full-width {
  width: 100%;
}

.w-language-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 35px;
  max-width: 35px;
  margin-left: 5rem;
  padding: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid white;
  border-radius: 50%;
  background: transparent;
  color: white;

  &:hover {
    cursor: pointer;
  }
}

.w-language-switch-drawer {
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 25px;
  color: white;
}

.w-menu-container {
  background: #307480;
}

.w-btn-mint {
  display: block;
  margin-left: auto;
  margin-right: auto;

  .w-btn-mint-wallet {
    padding-bottom: 8px!important;
    padding-top: 8px!important;
    text-transform: initial!important;
    font-weight: bold!important;
    border-radius: 0.5rem!important;
  }
}

.w-click-ptr {
  cursor: pointer;
}

.w-menu-route-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-underline: none;
  color: $white !important;
  margin-right: 3rem;

  p:hover, &:hover {
    color: $secondary-bright;
  }

  span {
    text-decoration: none;

    &:hover {
      color: $secondary-bright;
    }
  }

  p {
    justify-content: center;
    align-items: center;
    padding-left: 0.25rem;
    color: $white;
    margin-right: 4rem;
    font-size: 17px;
  }
}

.w-span {
  color: $secondary-light;
}

.w-fit-content {
  height: fit-content !important;
}

.w-carousel-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.w-img-slideshow {
  border-radius: 0.5rem;
  box-shadow: 3px 3px 1px $secondary-dark-opacity;

}

.w-utility-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

  .w-left-section {
    width: 50%;
  }

  .w-right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.w-scroll-arrow {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: -7rem;
}

.w-clouds-background {
  width: 100%;
  height: 100vh;
  display: flex;
  background-size: cover;
  background-image: $clouds-background;
  background-repeat: no-repeat;

  &.waves-2 {
    background-image: $clouds-background2;
  }

  &.waves-3 {
    background-image: $clouds-background3;
  }

  &.waves-4 {
    background-image: $clouds-background4;
  }

  &.waves-5 {
    background-image: $clouds-background5;
  }

  &.waves-6 {
    background-image: $clouds-background6;
  }

  &.waves-7 {
    background-image: $clouds-background7;
  }
}

.w-100 {
  width: 100%;
}

.w-column {
  flex-direction: column;
}

.w-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.w-flex-start {
  align-items: flex-start;
}

.w-align-left {
  text-align: left;
}

#w-airdrop-stepper {
  width: 50%;
  margin-top: 5rem;
}

.w-white {
  color: $white !important;
}
.w-error {
  color: $danger;
  font-weight: bold;
  letter-spacing: 1px;
}

.w-highlight {
  font-weight: bold;
  color: $primary !important;

  &.accent {
    color: #206d7a !important;
  }
}

.w-highlight-bordered {
  border-radius: 10%;
  font-size: 13px;
  padding-left: 2px;
  padding-right: 2px;
  color: #80aabb;
  font-weight: bold;
  border: 1px dashed $secondary-bright;
}

.w-highlight-extra {
  font-weight: bold;
  background: #0C8B99;
  background: linear-gradient(to right, #0C8B99 35%, #246C70 98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.w-link-as-button {
  padding: 0.25rem 1rem;
  color: #307480 !important;
}

.w-tag {
  display: inline-flex;
  border: 2px dashed $white;
  border-radius: $borderRadius;
  font-size: 14px;
  padding: 0.7rem;
  background-color: $secondary-light;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  letter-spacing: 0.12rem;
  margin-bottom: 1.75rem;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
}

.w-creators-image {
  border-radius: 25px;
  box-shadow: $multi-step-shadow;
}

.w-creator-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 500px;
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 15px;
  }

}

.w-text-center {
  text-align: center;
}

.w-text-start {
  text-align: left;
}

.w-sub-text {
  color: $accent;
}

.w-small-text {
  font-size: 12px;
}

.w-no-spacing {
  margin: 0;
  padding: 0;
}

.w-no-spacing-bottom {
  margin-bottom: 0.5rem;
  padding-bottom: 0;
}

.w-icon {
  color: $secondary;

  &.large {
    font-size: 80px;
  }

  &.hoverable:hover {
    cursor: pointer;
  }

  &.moving {
    position: relative;
    opacity: 0.9;
    animation: arrowMovingUpDown 3s ease-in-out infinite;
  }
}

.w-card {
  transition: top ease-in-out 0.2s;
  position: relative;
  width: 350px;
  top: 0;
  min-height: 350px;
  height: fit-content;
  background: $linear-gradient-3;
  box-shadow: $multi-step-shadow;
  padding: 3.5rem 1rem 0 1rem;
  margin: $offset-1;
  border-radius: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.mint-success {
    min-height: unset;
    height: 290px;

    p {
      margin-top: 1rem;
      padding: 0;
    }
  }

  &:hover {
    top: -5px;
    cursor: pointer;
  }

  .w-card-separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 70px;
    height: 2px;
    background: $white;
  }

  .w-card-header {
    padding: 0;
    margin: 0;
    font-size: 40px;
    color: $white;
  }

  .w-card-body {
    margin-top: 0.8rem;
    color: $white;
    text-align: justify;

    a {
      color: white;
    }
  }

  .w-tag {
    border-radius: 0 25px 0 25px;
    position: absolute;
    top: 0;
    right: 0.1rem;
    border-style: solid dashed;
    border-width: 0 0 2px 2px;
    border-right-color: transparent;
  }

  .w-card-bottom {
    width: 100%;
    position: absolute;
    padding-top: 1rem;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: $borderRadius;
    border-top: 2px solid $white;
    padding-bottom: 1rem;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: $secondary-light;
    align-items: center;

    a {
      font-size: 18px;
      color: $white !important;
    }
  }


  p {
    margin-top: 3rem;
  }

  &.step-1 {
    margin-top: 20rem;
    margin-left: 2rem
  }

  &.step-2 {
    margin-top: 10rem;
    margin-left: 2rem
  }

  &.step-3 {
    margin-top: 0;
    margin-left: 2rem
  }

}

.w-auction {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: $secondary-dark;
  border: 5px dashed white;
}

.w-contact-product-section-header {
  position: absolute;
  top: 6rem;
  left: 6rem;
}

.w-span {
  padding: $offset-1;
  margin-top: $offset-1;
  margin-bottom: $offset-1;
  color: $secondary-light
}

.w-chip:hover, .w-chip:active {
  background-color: $secondary !important;

  span {
    color: $text-accent !important;
  }
}

.menu-link-item {
  padding: 10px;
  color: white;
  text-decoration: none;

  &:visited {
    color: white;
  }
}

.w-main-intro-header {
  text-align: center;
  margin-bottom: 0;
  margin-top: 10rem;
  font-size: clamp(1.5rem, -0.875rem + 8.333vw, 3.5rem)!important;
}

.w-main-page-header {
  width: 100%;
  position: relative;
  text-align: center;
  font-size: clamp(1.5rem, -0.875rem + 8.333vw, 3.5rem);
  margin-left: 0;
  opacity: 0.7;
  color: $secondary;
}

.w-utility-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  left: 25%;
  text-align: left;
  color: $secondary-bright;
  font-size: 23px;
  transition: left ease 1s;

  &:hover {
    display: flex;
    cursor: pointer;
    left: 28%;
  }

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    aspect-ratio: 1/1;
    font-size: 30px;
    padding: 1rem;
    margin-right: 2rem;
    border-radius: 50%;
    background: #ccedee;
    opacity: 0.8;
    border: 5px solid $secondary-bright;
  }
}

.w-paper {
  background: $linear-gradient-3 !important;
  box-shadow: $multi-step-shadow !important;
  min-width: 350px;
  min-height: 350px;

  p {
    color: black;
  }

  border-radius: 25px !important;
  padding: 1.5em;
  margin-right: 1.8em;
  margin-left: 1.8em;

  h2 {
    text-align: center;
    color: $white;
  }
}

.w-nft-man {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 400px;
  height: 400px;
  background-image: url('assets/artwork/wavect-main-nft.png');
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(5px 10px 4px #6dc7d5);
  animation: MakeNFTManAppear 1s ease-in-out forwards;
}

.w-underline-marker {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.625rem;
    left: -0.5rem;
    right: -0.5rem;
    height: 0.75rem;
    z-index: -1;
    background-image: url('assets/misc/text-underline-marker.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* Unused for now, but kept as it worked in general */
.w-utility-man-nft {
  position: absolute;
  left: -500px;
  width: 600px;
  height: 600px;
  background-image: url("assets/artwork/wavect-main-nft.png");
  filter: drop-shadow(5px 10px 4px #6dc7d5);
  background-size: contain;
  animation: MoveUtilityNFTManUpAndDown 4s linear infinite;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid $secondary-bright;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}


.w-img-with-shadow {
  filter: drop-shadow(1px 1px 1px #307480);
  border-radius: 50%;
}

.w-ernesto-intro {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: #1f2b2f;
    font-style: italic;
  }
}

.w-educational-page-header {
  padding: 1rem;
}

#educational-page {
}

.bubble {
  transition: top ease-in-out 0.2s;
  position: relative;
  min-width: 80px !important;
  min-height: 20px !important;
  left: 200px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  width: 300px;
  background: #78bcbe;
  border-radius: 40px;
  padding: 24px;
  text-align: center;
  box-shadow: $multi-step-shadow;
  color: $white;

  &:hover {
    top: -5px;
  }
}

.w-discord-button {
  padding: .8rem;
  background-color: $secondary-dark !important;
  border: 1px solid #78bcbe;
  color: white !important;
  font-weight: bold;
  border-radius: 10px;
}

.bubble-bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 24px solid #78bcbe;
  border-right: 12px solid transparent;
  border-top: 12px solid #78bcbe;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}

#educational-page {
  display: block;
  flex-direction: column;
  height: 100vh;

  h1 {
    margin: 0;
  }
}

.w-hr {
  width: 80px;
}

#register-section {
  flex-direction: column;
}

.w-register {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 400px;
  min-height: 300px;
  height: fit-content;
  border-radius: 25px;
  background: $linear-gradient-1;
  border: 2px solid $secondary;
  box-shadow: $multi-step-shadow;

  img {
    opacity: 0.9;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    width: 100%;
  }

  .text {
    position: relative;
    padding: 0;
  }

  .w-video-register-header {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    font-weight: normal;
    color: white;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 45px;
  }

  .w-video-register-date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 0.4rem 0.4rem 0;
    margin-bottom: 0;
    font-weight: normal;
    background: transparent;
    color: $white;
    opacity: 1;
    font-size: 20px;
    text-align: center;
  }

  .w-video-register-content {
    margin: 0;
    padding: 0.8rem;
    font-size: 18px;
    color: $white;
  }

  .w-register-btn-container {
    display: flex;
    width: 100%;
  }
  .w-link {
    background: #4a8691;
    border-radius: 25px;
    color: white;
  }
  .w-register-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 70px;
    width: 50%;
    bottom: 0;
    font-size: 18px;
    text-align: center;
    color: $secondary-bright !important;
    background-color: $white;
    opacity: 0.9;

    &:hover {
      opacity: 0.8;
    }
  }
  .w-register-btn-left {
    border-bottom-left-radius: 25px;
  }
  .w-register-btn-right {
    border-bottom-right-radius: 25px;
    border-left: 1px solid $secondary-light;
  }
}

.w-social-container-header {
  margin-top: 5rem;
  margin-bottom: -2rem;
  text-align: center;
}

.w-social-container {
  margin-top: 6rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .w-social-row {
    top: 0;
    position: relative;
    width: 200px;
    height: 200px;
    transition: top ease-in-out 0.2s;

    &:hover {
      cursor: pointer;
      top: -30px;
    }
  }

}

.w-icon-social {
  height: 75px !important;
  width: 75px !important;
  color: #307480 !important;
  padding: 1rem;
  box-shadow: $multi-step-shadow;
  border-radius: 50%;
}

.w-icon-button {
  :hover {
    background: none !important;
  }
}

#smart-suggestions-section {
  p {
    width: 50%;
    font-size: 20px !important;
    line-height: 40px;
    text-align: left;
  }
  .w-clickable {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    padding: 0.3rem;
    border: 3px solid #478e98;
    background: #265962;
    border-radius: 5px;
    color: white;

    &:hover {
      cursor: pointer;
      background: #638b92;
    }
  }
}

.back-to-start {
  margin-top: -1rem;
  color: #78bcbe;
}
