@import "_variables.module.scss";

@media (max-width: 1100px) {

  h1 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 42px;
    color: $secondary;
  }

  h2 {
    padding-top: 3rem;
    padding-bottom: 1rem;
    color: $secondary-bright;
    font-size: 40px;
    font-weight: bold;
  }

  h3 {
    color: $secondary;
    font-weight: bold;
    font-size: 30px;
  }

  .w-center-center {
    flex-direction: column;
  }
  .w-clouds-background {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  #w-airdrop-stepper {
    display: none;
  }
  .w-clouds-background {
    background-image: url('assets/background/mobile-background.svg');
  }

  .w-scroll-arrow {
    margin-top: 17rem!important;
  }

  /** CREATORS */
  #creators {
    padding-top: 5rem;
  }
  #creators-section {
    flex-direction: column;

    #creators-image {
      margin: 0 !important;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-top: 2rem;
        width: 90vw;
      }
    }

    .w-creator-body {
      p {
        text-align: left;
        padding: 0.5rem;
      }

      img {
        width: 80%;
      }
    }


    #projects-card-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .w-card {
        width: 80%;
      }

      .w-card-header {
        font-size: 25px;
      }
    }

    h1 {
      margin: 0;
    }

    .w-section {
      height: fit-content;
      min-height: 100vh;
      justify-content: center;
      align-items: center;

      .w-row {
        flex-direction: column;
      }

      .w-card {
        height: fit-content;
        padding-bottom: 2rem;
        min-height: 270px;
        width: 300px;
        margin-bottom: 2rem;

        &:hover {
          top: unset;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 10rem;
        }
      }

      .w-contact-product-section-header {
        position: unset;
        display: flex;
        flex-direction: column;
        margin: 10rem 0 2rem;

        h3 {
          text-align: center;
        }
      }
    }

    .MuiAvatar-root {
      height: $avatar-size-standard;
      width: $avatar-size-standard;
    }

    .MuiAvatar-img {
      width: $avatar-size-standard;
      height: $avatar-size-standard;
      background-size: cover;
    }

    .w-mobile-bottom-info {
      display: flex !important;
      flex-direction: column;
      text-align: center;
      margin-bottom: 2rem;
      padding-bottom: 2rem;

      a {
        color: $accent !important;
      }

    }

    .step-1, .step-2, .step-3 {
      margin: 0;
    }
  }

  /** MAIN PAGE */
  #mainPage {
    .w-section {
      align-items: center;
      justify-content: center;
    }

    #mint-body {
      flex-direction: column;
    }

    #card-utility {
      margin: 0 !important;
      margin-bottom: 4rem !important;
    }

    .w-main-page-header {
      //font-size: 20px;
      padding: 0;
      margin: 0 0 2em;
    }

    hr {
      width: 100px;
    }

    .w-utility-container {
      justify-content: flex-start;
      align-items: start;
      flex-direction: column;
      margin-top: 1rem;

      .w-left-section {
        width: 100vw;
        display: flex;
        align-items: start;
        justify-content: flex-start;
      }

      .w-right-section {
        margin-top: 5rem;
        width: 100vw;
      }

      .w-utility-item {
        width: 80%;
        left: 5%;
        font-size: 18px;

        span {
          font-size: 20px;
          width: 15px;
          height: 15px;
        }
      }
    }

    .w-left-section {
      flex-direction: column;
    }

    .w-card {
      min-width: 250px !important;
      width: 310px;
      margin-bottom: 3rem;
    }
  }

  /** EDUCATIONAL PAGE */
  #educational-page {

    .w-educational-page-header {
      p {
        font-size: 15px;
      }
    }

    .w-ernesto-intro {
      position: relative;
      display: flex;
      width: 80%;
      left: -30px !important;
      margin-top: 120px;
      justify-content: flex-start;
      align-items: start;

      .bubble {
        position: absolute;
        left: 55px;
        top: 5px;
        padding: 0.5rem;
        font-size: 14px;
        max-width: 80%;
      }

      img {
        display: flex;
        height: 150px;
        width: 150px;
      }
    }
  }

  /** REGISTER AND SOCIAL SECTION*/
  #register-section {
    flex-direction: column !important;
    width: 100%;

    .w-social-container {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 80%;
      flex-wrap: wrap;
      padding-bottom: 3rem;
    }

    .w-social-row {
      height: fit-content;
      max-width: fit-content;
      justify-content: center;
      align-items: center;

      &:hover {
        top: unset;
      }
    }

    .w-mobile-bottom-info {
      display: flex !important;
      padding-bottom: 2rem;
    }

    .w-register {
      margin-top: 7rem;
      width: 90%;
    }

    .w-icon-button {
    }

    .w-icon-social {
      width: 50px !important;
      height: 50px !important;
    }

    .w-more-information {
      flex-direction: column;
      max-width: unset;
      padding: 1rem !important;
    }

    #video-area {
      flex-direction: column;

      .w-video-register-header {
        font-size: 35px;
      }

      .w-video-register-date {
        font-size: 20px;
      }

      .w-video-register-content {
        font-size: 15px;
      }

      .w-more-information {
        p {
          font-size: 15px;
          text-align: left;
        }
      }
    }
  }

  /** CREATOR PAGE*/
  #web3Content {
    flex-direction: column;
  }
  .w-connect-wallet-button {
    background-color: $secondary !important;
    height: 40px;
    overflow: hidden !important;
    margin-right: 0 !important;
  }

  .w-pretty-address-root {
    overflow: hidden !important;
  }

  .w-main-intro-header {
    margin-top: 8rem;
  }

  .w-mobile-menu-footer {
    padding-top: 30px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    background-image: url("assets/logo/wavect-white-logo.png");
    background-size: contain;
    width: 120px;
    height: 50px;
    background-repeat: no-repeat;
  }

  .w-utility-man-wrapper {
    display: none;
  }

  .w-nft-man {
    margin: 0 !important;
    top: 10%;
    max-width: 80vw;
    animation: MoveUpDownMobile 2.0s ease !important;
  }

  .w-nft-man {
    margin-top: 25rem;
  }

}

@media (min-width: 1200px) {
  @keyframes MoveUtilityNFTManUpAndDown {
    0%, 100% {
      top: -50%;
    }
    50% {
      top: -55%;
    }
  }

  @keyframes MakeNFTManAppear {
    0% {
      width: 2px;
      height: 2px;
      opacity: 0;
    }
    100% {
      opacity: 1;
      width: 400px;
      height: 400px;
    }
  }

  @keyframes MoveBasicCube {
    0%, 100% {
      rotate: -30deg;
      top: 39%;
    }
    50% {
      rotate: -2deg;
      top: 40%;
    }
  }
}

