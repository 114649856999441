$offset-1: 0.5rem;
$offset-2: 1rem;
$offset-3: 1.5rem;
$offset-4: 2rem;
$offset-5: 3rem;
$offset-6: 4rem;


.m1 {
  margin: $offset-1;
}

.m-2 {
  margin: $offset-2;
}

.m-3 {
  margin: $offset-3;
}

.m-4 {
  margin: $offset-4;
}

.m-5 {
  margin: $offset-5;
}

.m-6 {
  margin: $offset-6;
}

.m-t-1 {
  margin-top: $offset-1 !important;
}

.m-t-2 {
  margin-top: $offset-2 !important;
}

.m-t-3 {
  margin-top: $offset-3 !important;
}

.m-t-4 {
  margin-top: $offset-4 !important;
}

.m-t-5 {
  margin-top: $offset-5 !important;
}

.m-t-6 {
  margin-top: $offset-6 !important;
}

.m-b-1 {
  margin-bottom: $offset-1 !important;
}

.m-b-2 {
  margin-bottom: $offset-2 !important;
}

.m-b-3 {
  margin-bottom: $offset-3 !important;
}

.m-b-4 {
  margin-bottom: $offset-4 !important;
}

.m-b-5 {
  margin-bottom: $offset-5 !important;
}

.m-b-6 {
  margin-bottom: $offset-6 !important;
}

.m-r-1 {
  margin-right: $offset-1 !important;
}

.m-r-2 {
  margin-right: $offset-2 !important;
}

.m-r-3 {
  margin-right: $offset-3 !important;
}

.m-r-4 {
  margin-right: $offset-4 !important;
}

.m-r-5 {
  margin-right: $offset-5 !important;
}

.m-r-6 {
  margin-right: $offset-6 !important;
}

.m-l-1 {
  margin-left: $offset-1 !important;
}

.m-l-2 {
  margin-left: $offset-2 !important;
}

.m-l-3 {
  margin-left: $offset-3 !important;
}

.m-l-4 {
  margin-left: $offset-4 !important;
}

.m-l-5 {
  margin-left: $offset-5 !important;
}

.m-l-6 {
  margin-left: $offset-6 !important;
}

.m-lr-1 {
  margin-left: $offset-1 !important;
  margin-right: $offset-1 !important;
}

.m-lr-2 {
  margin-left: $offset-2 !important;
  margin-right: $offset-2 !important;
}

.m-lr-3 {
  margin-left: $offset-3 !important;
  margin-right: $offset-3 !important;
}

.m-lr-4 {
  margin-left: $offset-4 !important;
  margin-right: $offset-4 !important;
}

.m-lr-5 {
  margin-left: $offset-5 !important;
  margin-right: $offset-5 !important;
}

.m-lr-6 {
  margin-left: $offset-6 !important;
  margin-right: $offset-6 !important;
}

.p-1 {
  padding: $offset-1 !important;
}

.p-2 {
  padding: $offset-2 !important;
}

.p-3 {
  padding: $offset-3 !important;
}

.p-4 {
  padding: $offset-4 !important;
}

.p-5 {
  padding: $offset-5 !important;
}

.p-6 {
  padding: $offset-6 !important;
}

.p-t-1 {
  padding-top: $offset-1 !important;
}

.p-t-2 {
  padding-top: $offset-2 !important;
}

.p-t-3 {
  padding-top: $offset-3 !important;
}

.p-t-4 {
  padding-top: $offset-4 !important;
}

.p-t-5 {
  padding-top: $offset-5 !important;
}

.p-t-6 {
  padding-top: $offset-6 !important;
}

.p-b-1 {
  padding-bottom: $offset-1 !important;
}

.p-b-2 {
  padding-bottom: $offset-2 !important;
}

.p-b-3 {
  padding-bottom: $offset-3 !important;
}

.p-b-4 {
  padding-bottom: $offset-4 !important;
}

.p-b-5 {
  padding-bottom: $offset-5 !important;
}

.p-b-6 {
  padding-bottom: $offset-6 !important;
}

.p-r-1 {
  padding-right: $offset-1 !important;
}

.p-r-2 {
  padding-right: $offset-2 !important;
}

.p-r-3 {
  padding-right: $offset-3 !important;
}

.p-r-4 {
  padding-right: $offset-4 !important;
}

.p-r-5 {
  padding-right: $offset-5 !important;
}

.p-r-6 {
  padding-right: $offset-6 !important;
}

.p-l-1 {
  padding-left: $offset-1 !important;
}

.p-l-2 {
  padding-left: $offset-2 !important;
}

.p-l-3 {
  padding-left: $offset-3 !important;
}

.p-l-4 {
  padding-left: $offset-4 !important;
}

.p-l-5 {
  padding-left: $offset-5 !important;
}

.p-l-6 {
  padding-left: $offset-6 !important;
}

.p-lr-1 {
  padding-left: $offset-1 !important;
  padding-right: $offset-1 !important;
}

.p-lr-2 {
  padding-left: $offset-2 !important;
  padding-right: $offset-2 !important;
}

.p-lr-3 {
  padding-left: $offset-3 !important;
  padding-right: $offset-3 !important;
}

.p-lr-4 {
  padding-left: $offset-4 !important;
  padding-right: $offset-4 !important;
}

.p-lr-5 {
  padding-left: $offset-5 !important;
  padding-right: $offset-5 !important;
}

.p-lr-6 {
  padding-left: $offset-6 !important;
  padding-right: $offset-6 !important;
}
.gumroad-button {
  color: #0C8B99 !important;
  font-weight: bold;
}

.w-section {
  height: 110vh;

  &.starter {
    height: 100vh;
  }
}

.w-center-center {
  display: flex;
  justify-content: center;
  align-items: center;

  &.column {
    flex-direction: column;
  }
}
