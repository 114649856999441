
@import "src/shared-libs/wavect-styles/core";
@import "src/shared-libs/wavect-styles/variables.module";
@import "src/shared-libs/wavect-styles/components";
@import "src/shared-libs/wavect-styles/layout";
@import "src/shared-libs/wavect-styles/media-queries";
@import "src/shared-libs/wavect-styles/custom-reusable";
@import "src/shared-libs/wavect-styles/animations";

/**
 * this file should barely contain any scss rules, put the into their respective files.
 */

* {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

#root {
  height: 100vh;
  width: 100%;
}

