/**
 * Define basic colors of Wavect. If needed in JS, create a variable in _export.module.scss
 */
$primary: #38538c;
$primary-light: #435b8f;
$primary-bright: #4f6491;
$secondary: #459da1;
$secondary-light: #78bcbe;
$secondary-bright: #31a8a9;
$secondary-dark: #4a8691;
$secondary-dark-opacity: rgba(74, 134, 145, 0.5);
$accent: #5286b0;
$accent-light: #5c8aaf;
$accent-bright: #6f92af;
$danger: #f65b5b;
$danger-light: #ff7a7a;
$danger-bright: #ff8b8b;
$dark-night: #1f2b2f;
$dark: #23353a;
$white: #f1f1f1;
$text-accent: $white;

$borderRadius: 1.5rem;

$avatar-size-standard: 150px;
$avatar-size-large: 200px;

/**
* custom wavect-styles
 */
$clouds-background: url("assets/background/background.svg");
$clouds-background2: url("assets/background/background_2.svg");
$clouds-background3: url("assets/background/background_3.svg");
$clouds-background4: url("assets/background/background_4.svg");
$clouds-background5: url("assets/background/background_5.svg");
$clouds-background6: url("assets/background/background_6.svg");
$clouds-background7: url("assets/background/background_7.svg");
$multi-step-shadow: rgb(157, 208, 211) 5px 5px, rgb(162, 218, 220) 10px 10px, rgb(195, 230, 232) 15px 15px, rgb(206, 247, 253) 20px 20px, rgba(206, 247, 253, 0.05) 25px 25px;
$linear-gradient-1: linear-gradient(90deg, rgba(69, 157, 161, 1) 0%, rgba(44, 106, 125, 1) 100%);
$linear-gradient-2: linear-gradient(90deg, rgba(44, 106, 125, 1) 0%, rgba(69, 157, 161, 1) 100%);
$linear-gradient-3: linear-gradient(90deg, rgba(44, 106, 125, 0.67) 0%, rgba(69, 157, 161, 0.67) 100%);
